@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  h1 {
    @apply text-3xl font-light mb-4;
  }
  h2 {
    @apply text-2xl font-light mb-3;
  }
  h3 {
    @apply text-xl font-light mb-2;
  }
  h4 {
    @apply text-lg font-light mb-2;
  }
  h5 {
    @apply font-light mb-1;
  }
  div {
    @apply text-sm;
  }
  form {
    @apply rounded-lg;
  }
  input {
    @apply rounded-lg border px-2 py-1 mb-3 text-sm;
  }
  label {
    @apply block text-xs font-light px-1;
  }
  section {
    @apply my-4;
  }
  .button,
  button[type="submit"] {
    @apply rounded-full bg-blue-600 px-2.5 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600;
  }
  .link {
    @apply rounded-full bg-white px-3 py-1.5 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
  }
  .badge-outline {
    @apply inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10;
  }
  .badge {
    @apply inline-flex items-center rounded-full bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700;
  }
}

html,
body,
_ {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}
